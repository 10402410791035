import { ApiResponse } from "@services";
import {
    AnalysisService,
    BaseAnalysisRequest,
    BaseAnalysisResponse
} from "@services/space/analysis/models";
import { RheologyAnalysisServiceImplementation } from "@services/space/analysis/implementations";
import { RheologyTTSServiceImplementation } from "@services/space/analysis/rheology-tts-implementations";

const analysisServiceDictionary: { [key: string]: () => AnalysisService } = {
    cyRheology: () => new RheologyAnalysisServiceImplementation(),
    cyRheologyTTS: () => new RheologyTTSServiceImplementation()
    // Add more analysis types and their corresponding services here
};

export const fetchAnalysisResponse = async <T extends BaseAnalysisResponse>(
    request: BaseAnalysisRequest
): Promise<ApiResponse<T>> => {
    const serviceFactory =
        analysisServiceDictionary[
            request.analysisType as keyof typeof analysisServiceDictionary
        ];

    if (!serviceFactory) {
        throw new Error(`Unsupported analysis type: ${request.analysisType}`);
    }

    const service = serviceFactory();

    // console.log("API Request:", request);
    try {
        const response = await service.fetchAnalysisResults(request);
        // console.log("API Response:", response);
        return response as ApiResponse<T>;
    } catch (error) {
        console.error("Error performing analysis:", error);
        throw error;
    }
};

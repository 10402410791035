import { LogLevel, log } from "@cpchem/logging";

export type ApiFileResponse =
    | {
          filename: never;
          content: never;
          error: string;
      }
    | {
          filename: string;
          content: Blob;
          error?: never;
      };

export async function getApiFileResponse(
    res: Response
): Promise<ApiFileResponse> {
    const contentDisposition = res.headers.get("Content-Disposition");
    const contentDispositionParts = contentDisposition?.split(/\s*;\s*/) ?? [];

    let filename = "";
    for (
        let partIndex = 0;
        partIndex < contentDispositionParts.length;
        partIndex++
    ) {
        const match = contentDispositionParts[partIndex].match(
            /^filename\s*\*?\s*=\s*"?(.*?)"?$/
        );
        if (match?.length === 2) {
            [, filename] = match;
            break;
        }
    }

    const content = await res.blob();

    return {
        filename,
        content
    };
}

export type ApiResponse<T> =
    | {
          data?: never;
          error: string;
      }
    | {
          data: T;
          error?: never;
      }
    | {
          data?: never;
          error?: never;
      };

export function handle400Response() {
    log(
        `Bad request. Please check your request and try again.`,
        LogLevel.ERROR
    );
    return {
        error: "Bad request. Please check your request and try again."
    };
}

export function handle401Response() {
    log(`You are not authorized to make this call.`, LogLevel.ERROR);
    return {
        error: "You are not authorized to make this call."
    };
}

export function handle404Response() {
    log(
        `Data not found. Please check your request and try again.`,
        LogLevel.ERROR
    );
    return {
        error: "Data not found. Please check your request and try again."
    };
}

export function handle409Response() {
    log(
        `The server states that the request could not be completed due to a conflict with the current state of the target resource.`,
        LogLevel.ERROR
    );
    return {
        error: "The server states that the request could not be completed due to a conflict with the current state of the target resource."
    };
}

export function handle422Response() {
    log(
        `Your request was valid, but the server could not process it.`,
        LogLevel.ERROR
    );
    return {
        error: "Your request was valid, but the server could not process it."
    };
}

export function handle500Response() {
    log(
        `The server encountered an unexpected condition that prevented it from fulfilling the request`,
        LogLevel.ERROR
    );
    return {
        error: "The server encountered an unexpected condition that prevented it from fulfilling the request."
    };
}

export type errorResponseValue = { [key: number]: () => { error: string } };

export const errorResponseDictionary: errorResponseValue = {
    400: handle400Response,
    401: handle401Response,
    404: handle404Response,
    409: handle409Response,
    422: handle422Response,
    500: handle500Response
};

import React from "react";
import {
    AnalysisResponseType,
    DatasetAction,
    DatasetState
} from "./datasets-types";
import {
    AnalysisParameters,
    CyRheologyParameters
} from "../../interfaces/analysis-types";
import {
    DatasetConvertResponse,
    DatasetFormatData,
    StoredDataset
} from "@services/space/datasets/models";
import { RheologyAnalysisRequest } from "@services/space/analysis/models";
import { RheologyAnalysisServiceImplementation } from "@services/space/analysis/implementations";

export const formatNameToExtension: { [key: string]: string } = {
    rheocompass_txt: ".TXT",
    trios_rad: ".RAD"
};

export const formatExtensionToName: { [key: string]: string } = {
    ".RAD": "Trios RAD",
    ".TXT": "RheoCompass TXT"
};

export const SET_CY_RHEOLOGY_PARAMETERS = "SET_CY_RHEOLOGY_PARAMETERS";

export const setDataFormats = (
    payload: DatasetFormatData[]
): DatasetAction => ({
    type: "SET_DATA_FORMATS",
    payload: payload.map((format) => ({
        ...format,
        extension: formatNameToExtension[format.name] || format.name
    }))
});

export const setUserDatasets = (payload: StoredDataset[]): DatasetAction => ({
    type: "SET_USER_DATASETS",
    payload
});

export const setAnalysisParameters = (
    payload: AnalysisParameters
): DatasetAction => ({
    type: "SET_ANALYSIS_PARAMETERS",
    payload
});

export const setCyRheologyParameters = (payload: CyRheologyParameters) => ({
    type: "SET_CY_RHEOLOGY_PARAMETERS",
    payload
});

export const resetDataFormats = (): DatasetAction => ({
    type: "RESET_DATA_FORMATS"
});

export const clearUserDatasets = (): DatasetAction => ({
    type: "CLEAR_USER_DATASETS"
});

export const requestConvertDataset = (): DatasetAction => ({
    type: "REQUEST_CONVERT_DATASET"
});

export const postConvertDatasetSuccess = (
    payload: DatasetConvertResponse
): DatasetAction => ({
    type: "POST_CONVERT_DATASET_SUCCESS",
    payload
});

export const postConvertDatasetFailure = (payload: string): DatasetAction => ({
    type: "POST_CONVERT_DATASET_FAILURE",
    payload
});

export const postFetchLocalUserDatasetsFailure = (
    payload: string
): DatasetAction => ({
    type: "POST_FETCH_LOCAL_USER_DATASETS_FAILURE",
    payload
});

export const removeUserDataset = (payload: string): DatasetAction => ({
    type: "REMOVE_USER_DATASET",
    payload
});

export const rehydrateState = (payload: DatasetState): DatasetAction => ({
    type: "REHYDRATE_STATE",
    payload
});

export const requestAnalysis = (): DatasetAction => ({
    type: "REQUEST_ANALYSIS"
});

export const postRequestAnalysisSuccess = (
    payload: AnalysisResponseType
): DatasetAction => ({
    type: "POST_REQUEST_ANALYSIS_SUCCESS",
    payload
});

export const postRequestAnalysisFailure = (payload: string): DatasetAction => ({
    type: "POST_REQUEST_ANALYSIS_FAILURE",
    payload
});

export const fetchRheologyAnalysisResults =
    (payload: RheologyAnalysisRequest) =>
    async (dispatch: React.Dispatch<DatasetAction>) => {
        const service = new RheologyAnalysisServiceImplementation();
        const response = await service.fetchRheologyAnalysisResults(payload);
        if (response.data) {
            dispatch(postRequestAnalysisSuccess({ cyRheology: response.data }));
        } else if (response.error) {
            dispatch(postConvertDatasetFailure(response.error));
        }
    };

export type DatasetActionHandler = (
    state: DatasetState,
    action: DatasetAction
) => DatasetState;

import { ApiResponse } from "../../api-response";
import { DatasetConvertResponse, DatasetFormatsResponse } from "./models";

// Dataset Formats
export const initialDataSetsFormats: DatasetFormatsResponse = {
    datasetFormats: [{ name: "", title: "All Files" }]
};

export interface DatasetFormatsService {
    /** Get all Dataset Formats results */
    getAllDatasetFormats(): Promise<ApiResponse<DatasetFormatsResponse>>;
}

// Dataset Convert
export interface DatasetConvertService {
    /** Post Dataset Convert  Content */
    postDatasetConvert(
        uploadFile: Blob | ArrayBuffer | string | null
    ): Promise<ApiResponse<DatasetConvertResponse>>;
}

export const ServiceKey = "SpaceApi";
